.login-container {
  width: 400px;
  height: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 3px solid #00000080;
  border-radius: 25px;
  text-align: center;
}

.login-container h2 {
  text-align: center;
  font-size: 35px;
}

.form-group {
  margin-bottom: 20px;
}

.login-container label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
}

.login-container input[type="text"] {
  width: 100%;
  height: 50px;
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 20px;
}

.login-container input[type="password"] {
  width: 100%;
  height: 50px;
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 20px;
}

.login-container .error-message {
  color: red;
  margin-top: 10px;
}

.login-container button {
  margin-top: 10px;
  padding: 10px 10px;
  background-color: #ab3b39;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  width: 100px;
  height: 50px;
  font-size: 24px;
  font-weight: bold;
}

.login-container button:hover {
  background-color: #7c2220;
}
