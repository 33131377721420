.user-data-container {
  margin: 50px;
}

table {
  margin-top: 50px;
  width: 100%;
  border-collapse: collapse;
}

th {
  padding: 8px;
  text-align: center;
  border: 1px solid #353434;
}
td {
  padding: 4px;
  text-align:center;
  border: 1px solid #353434;
  font-size: 15px;
}

th {
  background-color: #bcbcbc;
  font-weight: bold;
  font-size: 16px;
}

.user-data-container h2 {
  text-align: center;
  font-size: 40px;
}

.user-data-container .edit-btn {
  background-color: #4caf50;
  color: white;
  margin: 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 8px;
  font-size: 15px;
  width: 80px;
  height: 30px;
}

.user-data-container .edit-btn:hover {
  background-color: #306c32;
}

.user-data-container .delete-btn {
  background-color: #f44336;
  color: white;
  margin: 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 8px;
  font-size: 15px;
  width: 80px;
  height: 30px;
}

.user-data-container .delete-btn:hover {
  background-color: #9e150e;
}

.update-modal-container .update-modal h3 {
  font-size: 25px;
}

.update-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* Allow the modal to be scrollable */
}

.update-modal {
  background-color: white;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  max-height: 90%; /* Limit the height of the modal */
  overflow-y: auto; /* Enable vertical scrolling */
}

.update-modal h3 {
  font-size: 25px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button[type="submit"] {
  background-color: #0db14b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px; /* Add some margin at the top */
}

button[type="submit"]:hover {
  background-color: #2f9434;
}

.filter-container .filter-btn {
  background-color: #0db14b;
  max-width: 10%;
  margin-left: 20px;
}

/* new */

/* Dataview.css */

.user-data-container {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.user-data-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
}

.filter-container label {
  margin-right: 10px;
  font-weight: bold;
}

.filter-container input {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-container input#filterUserId {
  width: 150px;
}

.filter-btn,
.search-btn {
  padding: 8px 10px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-btn:hover,
.search-btn:hover {
  background-color: #0056b3;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.edit-btn,
.delete-btn {
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-btn {
  background-color: #4caf50;
  color: white;
}

.edit-btn:hover {
  background-color: #45a049;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

.delete-btn:hover {
  background-color: #da190b;
}
.tableheaders th{
  font-size: 13px;
  text-align: center;
}

.update-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.update-modal h3 {
  margin-bottom: 20px;
}

.update-modal .form-group {
  margin-bottom: 15px;
}

.update-modal .form-group label {
  display: block;
  margin-bottom: 5px;
}

.update-modal .form-group input {
  width: calc(100% - 12px);
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.save-changes-btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-changes-btn:hover {
  background-color: #45a049;
}

.update-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.update-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.update-modal h2 {
  margin-top: 0;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: calc(100% - 12px);
  padding: 6px;
  margin-top: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

.update-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
  height: 40px;
  margin-left: 20px;
  margin-top: 10px;
  border-radius: 15px;
  cursor: pointer;
}

.update-btn:hover,
.cancel-btn:hover {
  opacity: 0.8;
}
