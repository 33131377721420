@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Custom styles for DatePicker */
  .react-datepicker-wrapper {
    @apply w-full;
  }

  .react-datepicker__input-container {
    @apply w-full;
  }

  .react-datepicker__input-container input {
    @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm;
  }
}

/* Spinner animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}