.thankyou-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.thankyou-modal {
  background: white;
  padding: 20px;
  border-radius: 20px;
  max-width: 600px;
  width: 90%;
  position: relative;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.thankyou-container {
  padding: 20px;
  position: relative;
}

.thankyou-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.welcome-image {
  width: 140px; /* Adjust size as needed */
}

.new-image {
  width: 70px; /* Adjust size as needed */
}

.thankyou-container h1 {
  font-size: 35px;
  margin-bottom: 40px;
  margin-top: 20px;
  color: #00509f;
}

.thankyou-container p {
  font-size: 18px;
  margin-bottom: 50px;
  font-weight: 450;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .thankyou-modal {
    padding: 10px;
    border-radius: 15px;
  }

  .welcome-image {
    width: 90px; /* Adjust size as needed */
  }

  .new-image {
    width: 45px; /* Adjust size as needed */
  }


  .thankyou-container h1 {
    font-size: 25px;
    margin-bottom: 0px;
    margin-top: 30px;
  }

  .thankyou-container p {
    font-size: 16px;
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 450;
  }
}
